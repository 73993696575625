import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import { renderFormFields } from 'lib/utils/renderFormFields';
import { useState } from 'react';
import classNames from 'classnames';
import Parse from 'react-html-parser';

const Form = ({ form, isPending, submit }) => {
    const [step, setStep] = useState(0);
    if (!form) return null;
    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        setValue,
        trigger,
    } = useForm();
    const data = watch();

    function handlePrev(e) {
        try {
            e.preventDefault();
            if (step === 0) return;
            setStep((step) => step - 1);
        } catch (error) {
            console.error(error.message);
        }
    }
    async function handleNext(e) {
        try {
            e.preventDefault();
            const fieldsToValidate = form.fields[step].fields.map(
                (step) => step.name
            );
            const isValid = await trigger(fieldsToValidate);
            if (isValid) {
                setStep((step) => step + 1);
            }
        } catch (error) {
            console.error(error.message);
        }
    }
    const steps =
        form.fields.filter((field) => field.type === 'form_step').length || 1;
    return (
        <form
            className="form form--lg"
            onSubmit={handleSubmit((data) => submit(form.type, data))}
        >
            {steps > 1 && (
                <div className="form__step-page">
                    {Parse(form.fields[step].title)}
                    <div className="form__step-counter">
                        {step + 1} / {steps}
                    </div>
                </div>
            )}
            <div className="form__fields">
                {form.fields.map((field, index) => {
                    if (field?.type === null) return null;
                    return (
                        <Fragment key={index}>
                            {renderFormFields(
                                field,
                                register,
                                handleSubmit,
                                control,
                                setValue,
                                data,
                                errors,
                                step,
                                index
                            )}
                        </Fragment>
                    );
                })}
                {!!Object.keys(errors).length && form?.validation && (
                    <div className="form__field form__field--full">
                        <div className="small text-crimson">
                            {form?.validation}
                        </div>
                    </div>
                )}
            </div>
            <div className={classNames({ form__buttons: steps > 1 })}>
                {steps > 1 && step > 0 && form?.fields[step]?.back && (
                    <Button
                        btn_style="ghost"
                        className="mr-2"
                        trigger={handlePrev}
                    >
                        {form.fields[step].back}
                    </Button>
                )}
                {step !== steps - 1 && (
                    <Button
                        trigger={handleNext}
                        icon="fa-solid fa-arrow-right"
                        icon_position="right"
                    >
                        {form.fields[step].continue}
                    </Button>
                )}
                {step === steps - 1 && (
                    <Button
                        type="submit"
                        disabled={isPending}
                        icon={form?.icon}
                        icon_position={'right'}
                        block={
                            form.submit_button_width === 'full_width'
                                ? true
                                : false
                        }
                    >
                        {isPending ? form.submitting : form.submit}
                    </Button>
                )}
            </div>
        </form>
    );
};

export default Form;
