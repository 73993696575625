/**
 * Features Block
 * A features block can showcase a set of features for a specific product.
 */

import Features1a from './variants/Features1a';
import Features1b from './variants/Features1b';
import Features1c from './variants/Features1c';

import Features2a from './variants/Features2a';
import Features2b from './variants/Features2b';
import Features2c from './variants/Features2c';

import Features3a from './variants/Features3a';
import Features3b from './variants/Features3b';

import Features4a from './variants/Features4a';
import Features4b from './variants/Features4b';

const prefix = 'features';

const Features = ({ ...block }) => {

   switch (block.variant) {
      case `${prefix}_1a`:
         return <Features1a {...block} />;
      case `${prefix}_1b`:
         return <Features1b {...block} />;
      case `${prefix}_1c`:
         return <Features1c {...block} />;
      case `${prefix}_2a`:
         return <Features2a {...block} />;
      case `${prefix}_2b`:
         return <Features2b {...block} />;
      case `${prefix}_2c`:
         return <Features2c {...block} />;
      case `${prefix}_3a`:
         return <Features3a {...block} />;
      case `${prefix}_3b`:
         return <Features3b {...block} />;
      case `${prefix}_4a`:
         return <Features4a {...block} />;
      case `${prefix}_4b`:
         return <Features4b {...block} />;
      default:
         return null;
   }
};

export default Features;
