import classNames from 'classnames';
import Media from 'components/Media';
import Parse from 'react-html-parser';

const Tab = ({ tab, activeTab, setActiveTab, iconStyle, grow, tabID }) => {
    return (
        <>
            <button
                className={classNames('tabs__tab', {
                    'tabs__tab--active': activeTab === tabID,
                    'tabs__tab--grow': grow,
                    'tabs__tab--icon-top': iconStyle === 'top',
                    'tabs__tab--icon-left': iconStyle === 'left',
                })}
                onClick={() => setActiveTab(tabID)}
                data-kontent-item-id={tab.id}
                data-kontent-element-codename={tab.codename}
            >
                {iconStyle && tab.media && (
                    <Media media={tab.media} className="tabs__icon" allowIcon />
                )}
                {tab?.title && (
                    <div className="tabs__title">{Parse(tab?.title)}</div>
                )}
                {tabID === 1 && (
                    <div
                        className={classNames('tabs__glider', [
                            `tabs__glider--${activeTab}`,
                        ])}
                    ></div>
                )}
            </button>
        </>
    );
};

export default Tab;
