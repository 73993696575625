import classNames from 'classnames';
import Button from 'components/Button';
import Introduction from 'components/Introduction';
import Media from 'components/Media';

const Tout1a = ({ introduction, settings, links, icon, meta }) => {
    return (
        <section
            className={classNames('section', {
                [`bg-${settings.theme}`]: settings.theme,
            })}
            {...meta}
        >
            <div className="container text-md-center">
                {icon && (
                    <Media
                        wrapperClassName={'mb-4'}
                        className="tout__icon"
                        media={icon}
                    />
                )}
                <Introduction {...introduction} size={'sm'} />
                {links && (
                    <div className="btn-group">
                        {links?.map((link, index) => {
                            return (
                                <Button
                                    key={index}
                                    {...link}
                                    btn_size={link.btn_size || 'lg'}
                                >
                                    {link.cta_text}
                                </Button>
                            );
                        })}
                    </div>
                )}
            </div>
        </section>
    );
};

export default Tout1a;
