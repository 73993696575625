import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import { useEffect } from 'react';
import { getVisibilityConditions } from 'lib/utils/getFormElementFields';

const Select = ({
    register,
    label,
    name,
    placeholder,
    visibility_conditions,
    validation,
    setValue,
    data,
    errors,
    width,
    control,
    options,
    reset,
    default_value,
    isMulti = false,
}) => {
    if (!options) return null;
    validation = {
        ...validation,
        pattern: {
            value: validation?.pattern
                ? new RegExp(validation.pattern.value)
                : null,
            message: validation?.pattern ? validation?.pattern?.message : null,
        },
    };
    useEffect(() => {
        if (!default_value) return;
        setValue(name, default_value);
    }, [default_value]);
    useEffect(() => {
        if (data[reset]) {
            setValue(name, '');
        }
    }, [data[reset]]);
    return (
        getVisibilityConditions({ data, visibility_conditions }) && (
            <div
                className={classNames('form__field', {
                    'form__field--full': width === 'full',
                    'form__field--half-new-line': width === 'half_new_line',
                })}
            >
                <label className="form__label">
                    {label}
                    <Controller
                        {...register(name, validation)}
                        ref={null}
                        control={control}
                        render={({ field }) => (
                            <ReactSelect
                                {...field}
                                value={
                                    data[name]
                                        ? options.find(
                                              (c) => c.value === field.value
                                          )
                                        : null
                                }
                                onChange={(val) => {
                                    if (isMulti) {
                                        field.onChange(val.map((c) => c.value));
                                    } else {
                                        field.onChange(val.value);
                                    }
                                }}
                                className={classNames('select', {
                                    'select--error': errors[name],
                                })}
                                placeholder={placeholder}
                                classNamePrefix="select"
                                options={options}
                                id="long-value-select"
                                isMulti={isMulti}
                                instanceId="long-value-select"
                            />
                        )}
                    />

                    {errors[name] && (
                        <div className="form__helper">
                            {errors[name]?.message}
                        </div>
                    )}
                </label>
            </div>
        )
    );
};

export default Select;
