import Introduction from 'components/Introduction';
import Tabs from 'components/Tabs';
import classNames from 'classnames';

const Tabs1c = ({ settings, introduction, tabs, meta }) => {
   return (
      <section
         className={classNames('section', {
            ...settings,
         })}
         {...meta}
      >
         <div className="container">
            <Introduction {...introduction} />
            <Tabs
               tabs={tabs}
               grow
            />
         </div>
      </section>
   );
};

export default Tabs1c;
