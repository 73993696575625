import Media from 'components/Media';

const Mosaic = ({ images }) => {
   return (
      <div className="mosaic">
         {images.map((image, index) => {
            return (
               <Media
                  wrapperClassName={`mosaic__item mosaic__item--${index}`}
                  key={index}
                  layout={'fill'}
                  objectFit={'cover'}
                  objectPosition={'center'}
                  media={image}
                  disableCaption
                  disableElevation
               />
            );
         })}
      </div>
   );
};

export default Mosaic;
