import Item from './elements/Item';
import classNames from 'classnames';

const Accordion = ({ items, numbered }) => {
   return (
      <div
         className={classNames('accordion', {
            'accordion accordion--numbered': numbered,
         })}
      >
         {items.map((item, index) => {
            const count = index + 1;
            return (
               <Item
                  key={index}
                  item={item}
                  count={count}
                  numbered={numbered}
               />
            );
         })}
      </div>
   );
};

export default Accordion;
