import Media from 'components/Media';
import RichText from 'components/RichText';
import Button from 'components/Button';
import Footnotes from 'components/Footnotes';


const Product = ({ id, codename, title, description, button, footnotes, media }) => {
	return (
		<article
			className='grid-md-12 last-of-type-mb-12'
			aria-label={title}
			data-kontent-item-id={id}
			data-kontent-element-codename={codename}
			data-kontent-add-button={!!codename}
			data-kontent-add-button-render-position='right'
			data-kontent-add-button-insert-position='after'
		>
			<div className='col-md-4'>{media && <Media media={media} />}</div>
			<div className='col-start-md-5 col-end-md-13'>
				{title && <h4>{title}</h4>}
				{description && <RichText className='mb-6' data={description} />}
				{button && (
					<Button {...button} btn_size={'md'} btn_style={'tertiary'}>
						{button.cta_text}
					</Button>
				)}
				{footnotes?.value && <Footnotes className='mt-8' footnotes={footnotes} />}
			</div>
		</article>
	);
};

export default Product;
