import Media from 'components/Media';
import Footnotes from 'components/Footnotes';
import Feature from '../elements/Feature';
import classNames from 'classnames';
import IntroButton from 'components/IntroButton';


const Features2b = ({
	settings,
	center_items,
	elevation,
	offset_media,
	introduction,
	button,
	media,
	features,
	footnotes,
	meta,
}) => {
	
	return (
		<section
			className={classNames('section features', {
				...settings
			})}
			{...meta}
		>
			<div className='container'>
				<IntroButton introduction={introduction} button={button} />
				<div
					className={classNames('grid-md-12 row-gap-14 row-gap-md-7 features__items', {
						'mt-md-6': offset_media,
					})}
				>
					<div className='col-start-md-8 col-end-md-13 order-1 order-md-2'>
						{media && <Media media={media} />}
					</div>
					<div
						className={classNames('col-md-6 grid-md-2  order-2 order-md-1', {
							'row-gap-md-12': !offset_media,
							'row-gap-20 row-gap-md-22 mt-8 mt-md-0': offset_media,
						})}
					>
						{features.map((feature, index) => {
							return (
								<Feature
									key={index}
									center_items={center_items}
									elevation={elevation}
									offset_media={offset_media}
									variant={1}
									{...feature}
								/>
							);
						})}
					</div>
				</div>
				{footnotes?.value && <Footnotes footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Features2b;
