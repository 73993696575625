import classNames from 'classnames';
import { useEffect } from 'react';
import {
    getRequiredConditions,
    getVisibilityConditions,
} from 'lib/utils/getFormElementFields';

const Radio = ({
    register,
    label,
    name,
    visibility_conditions,
    required_conditions,
    validation,
    type,
    data,
    errors,
    options,
    setValue,
    default_value,
    reset,
}) => {
    if (!options) return null;
    validation = {
        required: getRequiredConditions({
            data,
            required_conditions,
            required: validation.required,
        }),
        pattern: {
            value: validation?.pattern
                ? new RegExp(validation.pattern.value)
                : null,
            message: validation?.pattern ? validation?.pattern?.message : null,
        },
    };
    useEffect(() => {
        if (!default_value) return;
        setValue(name, default_value);
    }, [default_value]);
    useEffect(() => {
        if (data[reset]) {
            setValue(name, '');
        }
    }, [data[reset]]);

    return (
        getVisibilityConditions({ data, visibility_conditions }) && (
            <div
                className={'form__field form__field--full'}
                role="group"
                aria-label="radio buttons"
            >
                {label && (
                    <div className="form__label form__label--multi">
                        {label}
                    </div>
                )}
                <div
                    className={classNames('form__validation', {
                        'form__validation--error': errors[name],
                    })}
                >
                    {options.map((option, key) => {
                        return (
                            <label key={key} className="form__item">
                                <input
                                    className="form__selector"
                                    type={type}
                                    {...register(name, validation)}
                                    value={option.value}
                                />
                                <div className="form__check form__check--radio"></div>
                                {option.label}
                            </label>
                        );
                    })}
                    {errors[name] && (
                        <div className="form__helper">
                            {errors[name]?.message}
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default Radio;
