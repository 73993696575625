import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import classNames from 'classnames';
import ResourceButtons from '../elements/ResourceButtons';
import Media from 'components/Media';

const Resources2d = ({
    media,
    settings,
    introduction,
    footnotes,
    resources,
    meta,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...meta}
        >
            <div className="container">
                <div className="grid-md-12">
                    <div className="col-md-3">
                        <Media media={media} />
                    </div>
                    <div className="col-md-9 v-center">
                        <Introduction {...introduction} />
                        <div className="grid-lg-3 grid-md-2">
                            {resources.map((resource, index) => {
                                const size =
                                    resource?.media?.button?.btn_size ?? 'xs';
                                return (
                                    <ResourceButtons
                                        key={index}
                                        index={index}
                                        resource={resource}
                                        size={size}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>

                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};

export default Resources2d;
