import useWindowDimensions from 'lib/hooks/useWindowDimensions';
import JumpLinkContentZone from './elements/JumpLinkContentZone';
import NormalContentZone from './elements/NormalContentZone';

const ContentZone = ({ blocks, jump_links }) => {
    const { width } = useWindowDimensions();
    if (!blocks) {
        return null;
    }
    return (
        <>
            {!jump_links && <NormalContentZone blocks={blocks} />}
            {jump_links && (
                <JumpLinkContentZone
                    width={width}
                    blocks={blocks}
                    jump_links={jump_links}
                />
            )}
        </>
    );
};

export default ContentZone;
