import Button from 'components/Button';
import classNames from 'classnames';
import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import Media from 'components/Media';

const Tout4a = ({
    introduction,
    settings,
    title_style,
    links,
    icon,
    media,
    footnotes,
    offset_media,
    meta,
}) => {
    return (
        <div
            className={classNames('section tout tout--card', {
                [`section--top-${settings.spacing_top}`]: settings.spacing_top,
                [`section--bottom-${settings.spacing_bottom}`]:
                    settings.spacing_bottom,
            })}
            {...meta}
        >
            <div
                className={classNames(`tout__wrapper`, {
                    [`bg-${settings.theme}`]: settings.theme,
                })}
            >
                <div className="grid-lg-12 gap-0">
                    <div className="col-lg-6 tout__content order-1">
                        {icon && (
                            <Media
                                wrapperClassName={'mb-4'}
                                className="tout__icon"
                                media={icon}
                            />
                        )}
                        <Introduction
                            className={classNames({
                                'introduction--fancy': title_style === 'fancy',
                            })}
                            {...introduction}
                            size={'sm'}
                        />
                        {links && (
                            <div className="btn-group btn-group--stacked">
                                {links?.map((link, index) => {
                                    return (
                                        <Button
                                            key={index}
                                            {...link}
                                            btn_size={link.btn_size || 'lg'}
                                        >
                                            {link.cta_text}
                                        </Button>
                                    );
                                })}
                            </div>
                        )}
                        {footnotes?.value && (
                            <Footnotes
                                className={classNames({
                                    'mt-14': links,
                                })}
                                footnotes={footnotes}
                            />
                        )}
                    </div>
                    {media && (
                        <div className={'col-lg-6 order-2'}>
                            <Media
                                wrapperClassName={classNames('tout__media', {
                                    'tout__media--offset': offset_media,
                                })}
                                media={media}
                                disableCaption
                                disableElevation
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Tout4a;
