import LinkResource from './LinkResource';
import VideoResource from './VideoResource';

const ResourceSelector = ({ resource }) => {
	switch (resource?.media?.type) {
		case 'image_link':
			return <LinkResource {...resource} />;
		case 'pdf':
			return <LinkResource {...resource} />;
		case 'video':
			return <VideoResource {...resource} />;
		default:
			return null;
	}
};

export default ResourceSelector;
