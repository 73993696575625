import React from 'react';
import Media from '../Media';
import classNames from 'classnames';
import Link from 'components/Link';
import Footnotes from 'components/Footnotes';
import RichText from 'components/RichText';
import Icon from 'components/Icon';

const Card = ({
	id,
	codename,
	theme,
	className,
	title,
	media,
	description,
	button,
	footnotes,
	variant,
}) => {
	return (
		<Link href={button?.cta_link} className={classNames('card', {
			[`bg-${theme}`]: theme,
			[`${className}`]: className,
			[`card--${variant}`]: variant,
		})}
		id={id}
		codename={codename}
		add_button={!!codename}
		add_button_render_position='right'
		add_button_insert_position='after'>
		
				<Media wrapperClassName={'card__image'} media={media} disableElevation />
				<div className='card__body'>
					{title && <RichText className={'card__title'} data={{ value: `<h4>${title}</h4>` }} />}
					{description?.value && (
						<RichText
							className='card__description'
							data={
								variant === 2
									? {
											value: `${description.value} <div class="card__footnotes card__footnotes--hoverable">${footnotes.value}</div>`,
									  }
									: description
							}
						/>
					)}
					{footnotes?.value && <Footnotes className='card__footnotes' footnotes={footnotes} />}
					{description?.value && (
						<Icon
							wrapperIconClassName={'card__arrow-wrapper'}
							className='card__arrow'
							icon='fa-regular fa-circle-arrow-right'
						/>
					)}
				</div>
			
		</Link>
	);
};

export default Card;
