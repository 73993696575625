import Media from 'components/Media';
import RichText from 'components/RichText';

const PeopleModal = ({ media, job_title, full_name, description }) => {
	return (
		<div className='person person--modal'>
			<Media
				wrapperClassName={'person__image'}
				className='circle'
				layout={'fill'}
				objectFit='cover'
				media={media}
			/>
			<h4 className='person__fullname'>{full_name}</h4>
			<RichText className='person__occupation' data={job_title} />
			<RichText className='person__description' data={description} />
		</div>
	);
};

export default PeopleModal;
