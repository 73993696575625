export const getPreviewMode = () => {
    try {
        if (process.env.PREVIEW_MODE === undefined) {
            return false;
        }
        if (process.env.PREVIEW_MODE === 'true') {
            return true;
        }
        if (process.env.PREVIEW_MODE === 'false') {
            return false;
        }
    } catch (error) {
        console.log(error);
    }
};
