import IntroButton from 'components/IntroButton';
import Footnotes from 'components/Footnotes';
import RichText from 'components/RichText';
import classNames from 'classnames';

const Statistics2c = ({
    settings,
    introduction,
    statistics,
    button,
    footnotes,
    meta
}) => {
    return (
        <section
            
            className={classNames('section', {
                ...settings,
            })}
            {...meta}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                <div className={'grid-md-2 grid-lg-3'}>
                    {statistics.map((statistic, index) => {
                        const { id, codename, title, description, stat } =
                            statistic;
                        return (
                            <article
                                key={index}
                                className="statistic statistic--1 p-0"
                                data-kontent-item-id={id}
                                data-kontent-element-codename={codename}
                                data-kontent-add-button={!!codename}
                                data-kontent-add-button-render-position="right"
                                data-kontent-add-button-insert-position="after"
                            >
                                {stat && (
                                    <div className="statistic__stat statistic__stat--small">
                                        {stat}
                                    </div>
                                )}
                                <div className="statistic__content">
                                    {title && (
                                        <RichText
                                            className="statistic__title"
                                            data={title}
                                        />
                                    )}
                                    {description && (
                                        <RichText
                                            className="statistic__description"
                                            data={description}
                                        />
                                    )}
                                </div>
                            </article>
                        );
                    })}
                </div>

                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};

export default Statistics2c;
