import ContentZone from 'components/ContentZone';
import QuizResult from './QuizResult';

const Results = ({ form_element, thank_you_message, results }) => {
    return (
        <div className="form__results">
            {thank_you_message && <ContentZone blocks={thank_you_message} />}
            {form_element?.type === 'quiz' && results && (
                <div className="form__quiz-results">
                    {results.map((result) => {
                        return (
                            <QuizResult
                                key={result.id}
                                result={result}
                                form_element={form_element}
                                id={result.id}
                            />
                        );
                    })}

                    {form_element?.results_summary && (
                        <ContentZone
                            blocks={form_element.results_summary[0].content}
                        />
                    )}
                </div>
            )}

            {form_element?.type === 'likert' &&
                results &&
                form_element?.results_summary && (
                    <div className="container">
                        <ContentZone
                            blocks={
                                form_element.results_summary.find(
                                    (result) => result?.key === results
                                )?.content
                            }
                        />
                    </div>
                )}
        </div>
    );
};
export default Results;
