import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import convertArrayValues from 'lib/utils/convertArrayValues';
import { signIn } from 'next-auth/react';
import { getPreviewMode } from 'lib/utils/getPreviewMode';

const restrictedDomains = [
    '@cryolife.com',
    '@artivion.com',
    '@medtronic.com',
    '@itsjnj.com',
    '@bsci.com',
    '@abbott.com',
];

const useForm = ({ form, ref, form_element }) => {
    const router = useRouter();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [results, setResults] = useState(null);

    const callbackUrl =
        router?.query?.callbackUrl ||
        `/${router.locale === 'default' ? '' : router.locale}${router?.asPath}`;

    useEffect(() => {
        if (success === true) {
            ref.current.scrollIntoView();
        }
    }, [success]);

    useEffect(() => {
        // Reset form state on route change
        setSuccess(false);
        setError(null);
        setIsPending(false);
    }, [router.asPath]);
    const handleSubmit = async (type, values) => {
        const pageName = document.title.split('|')[0].trim();
        const pageURL = window.location.href;
        setIsPending(true);
        if (type === 'marketo') {
            form = parseInt(form.replace('form_', ''));
            const updatedValues = convertArrayValues(values);
            MktoForms2.loadForm(
                '//info.edwards.com',
                '769-NOZ-917',
                form,
                function (form) {
                    form.addHiddenFields({
                        ...updatedValues,
                        test1: pageName,
                        test2: pageURL,
                    });
                    const validated = form.validate();
                    if (!validated) {
                        setIsPending(false);
                        setError('Could not submit form');
                        return;
                    }
                    form.submit().onSuccess(function () {
                        setIsPending(false);
                        setError(null);
                        setSuccess(true);
                        dataLayer.push({
                            event: 'mktoLead',
                            mktoFormId: form.getId(),
                        });

                        return false;
                    });
                }
            );
        }
        if (type === 'docebo') {
            const formValues = JSON.stringify({
                ...values,
                RegistrationType: 'Docebo_Edwards',
                IsTesting:
                    process.env.DOCEBO_IS_TESTING === 'true' ? true : false,
            });
            try {
                const response = await fetch(
                    'https://externalreg.edwards.com/api/AzureADSignUp/CreateUserInvitation',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            apikey: process.env.DOCEBO,
                        },
                        body: formValues,
                    }
                );

                // Form error
                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.Message);
                }

                // If form is successfully submitted.
                setIsPending(false);
                setSuccess(true);
                setError(null);
            } catch (err) {
                setIsPending(false);
                setError(err.message);
            }
        }

        if (type === 'docebo-au') {
            values.PostCode = values.PostalCode;
            values.Country = 'Australia';
            const formValues = JSON.stringify({
                ...values,
                RegistrationType: 'Docebo_Edwards',
                Environment: process.env.Environment,
                UsageLocation: 'au',
                //IsTesting: true,
            });
            try {
                //restricted Domains
                if (
                    restrictedDomains.some((Domain) =>
                        values?.ProfessionalEmailAddress?.toLowerCase()?.includes(
                            Domain
                        )
                    )
                ) {
                    throw new Error(
                        'Sorry, we are unable to register your information. Please try again at a later time'
                    );
                }

                const response = await fetch(
                    'https://app-dv-ssomiddleware.azurewebsites.net/api/PostUserInvitation/NewGuestUser',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            apikey: process.env.MIDDLEWARE_API_KEY,
                        },
                        body: formValues,
                    }
                );

                // Form error
                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.Message);
                }

                // If form is successfully submitted.
                setIsPending(false);
                setSuccess(true);
                setError(null);
            } catch (err) {
                setIsPending(false);
                setError(err.message);
            }
        }
        if (type === 'quiz') {
            const responses = form_element.fields
                .filter((f) => f.type === 'radio')
                .map((field) => {
                    if (field.answer === values[field.name]) {
                        // If the question is answered correctly
                        return {
                            answer: values[field.name],
                            question: field.label,
                            result: field.correct,
                            correct: true,
                            id: field.id,
                        };
                    } else {
                        // If the question is answered incorrectly
                        return {
                            answer: values[field.name],
                            question: field.label,
                            result: field.incorrect,
                            correct: false,
                            id: field.id,
                        };
                    }
                });
            setResults(responses);
            setSuccess(true);
            setIsPending(false);
        }
        if (type === 'likert') {
            const valueCounts = {}; // total count of each option
            for (const key in values) {
                const value = values[key];
                if (valueCounts[value]) {
                    valueCounts[value]++;
                } else {
                    valueCounts[value] = 1;
                }
            }
            let maxCount = 0;
            let maxKey = ''; // The option with the highest score

            for (const key in valueCounts) {
                if (valueCounts[key] > maxCount) {
                    maxCount = valueCounts[key];
                    maxKey = key;
                }
            }
            setResults(maxKey);
            setSuccess(true);
            setIsPending(false);
        }
        if (type === 'pdf') {
            try {
                if (!form) {
                    throw new Error('Form ID must be provided');
                }
                const baseUrl = getPreviewMode()
                    ? 'preview.pdf.edwards.com'
                    : 'pdfapp.edwards.com';
                const res = await fetch(
                    `https://${baseUrl}/api/v1/pdf?template=${form}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-api-key': process.env.PDF_API,
                        },
                        body: JSON.stringify(values),
                    }
                );
                const data = await res.json();
                const id = data.data.id;
                const pdf = await fetch(`https://${baseUrl}/api/v1/pdf/${id}`, {
                    responseType: 'blob',
                    headers: {
                        'x-api-key': process.env.PDF_API,
                    },
                });

                const blob = await pdf.blob();
                const url = URL.createObjectURL(blob);
                const popUp = window.open(url, '_blank', 'popup=true')
                if (!popUp || popUp.closed || typeof popUp.closed == 'undefined') {
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank'
                    link.download = 'Form.pdf';
                    document.body.append(link);
                    link.click();
                    link.remove();
                }

                // If form is successfully submitted.
                setIsPending(false);
                setError(null);
            } catch (err) {
                setIsPending(false);
                setError(err.message);
            }
        }
        if (type === 'sso') {
            try {
                values.tenant = form.split('-')[1];
                const response = await signIn(
                    form,
                    { redirect: false },
                    values
                );
                if (!response.ok) {
                    throw new Error(form_element.api_validation);
                }
                router.push(callbackUrl);
                setIsPending(false);
            } catch (err) {
                setIsPending(false);
                setError(err.message);
            }
        }
    };

    return { success, error, isPending, results, handleSubmit };
};

export default useForm;
