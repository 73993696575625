/**
 * Accordion Block
 * An accordion block is used to reveal and hide sections of text content underneath an associated header.
 */

import Accordion1a from './variants/Accordion1a';
import Accordion1b from './variants/Accordion1b';

import Accordion2a from './variants/Accordion2a';
import Accordion2b from './variants/Accordion2b';

const prefix = 'accordion';

const Accordion = ({ ...block }) => {
    switch (block.variant) {
        case `${prefix}_1a`:
            return <Accordion1a {...block} />;
        case `${prefix}_1b`:
            return <Accordion1b {...block} />;
        case `${prefix}_2a`:
            return <Accordion2a {...block} />;
        case `${prefix}_2b`:
            return <Accordion2b {...block} />;
        default:
            return null;
    }
};

export default Accordion;
