import Parse from 'react-html-parser';
import classNames from 'classnames';

function QuizResult({ result, form_element, id }) {
    const field = form_element?.fields?.find((f) => f.id === id);
    function checkCorrect(option) {
        return option.value === field.answer;
    }
    function checkIncorrect(option) {
        return option.value === result.answer && result.answer !== field.answer;
    }
    return (
        <div className="last-child-mb-md-8">
            <p>
                <strong>{result.question}</strong>
            </p>
            <div>
                {field?.options?.map((option) => {
                    return (
                        <div
                            key={option.value}
                            className={classNames('form__result', {
                                'form__result--correct': checkCorrect(option),
                                'form__result--incorrect':
                                    checkIncorrect(option),
                            })}
                        >
                            <div
                                className={classNames('form__result-radio', {
                                    'form__result-radio--correct':
                                        option.value === result.answer,
                                })}
                            ></div>{' '}
                            {option.label}
                        </div>
                    );
                })}
            </div>
            <div className="mt-6">{Parse(result.result)}</div>
        </div>
    );
}
export default QuizResult;
