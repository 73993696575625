/**
 * Tout Block
 * A tout block is a visually distinct way to prompt the user to perform an action.
 */

import Tout1a from './variants/Tout1a';
import Tout1b from './variants/Tout1b';
import Tout1c from './variants/Tout1c';

import Tout2a from './variants/Tout2a';
import Tout2b from './variants/Tout2b';
import Tout2c from './variants/Tout2c';

import Tout3a from './variants/Tout3a';
import Tout3b from './variants/Tout3b';
import Tout3c from './variants/Tout3c';
import Tout3d from './variants/Tout3d';
import Tout3e from './variants/Tout3e';
import Tout3f from './variants/Tout3f';

import Tout4a from './variants/Tout4a';
import Tout4b from './variants/Tout4b';

import Tout5a from './variants/Tout5a';

const prefix = 'tout';

const Tout = ({ ...block }) => {
   switch (block.variant) {
      case `${prefix}_1a`:
         return <Tout1a {...block} />;
      case `${prefix}_1b`:
         return <Tout1b {...block} />;
      case `${prefix}_1c`:
         return <Tout1c {...block} />;
      case `${prefix}_2a`:
         return <Tout2a {...block} />;
      case `${prefix}_2b`:
         return <Tout2b {...block} />;
      case `${prefix}_2c`:
         return <Tout2c {...block} />;
      case `${prefix}_3a`:
         return <Tout3a {...block} />;
      case `${prefix}_3b`:
         return <Tout3b {...block} />;
      case `${prefix}_3c`:
         return <Tout3c {...block} />;
      case `${prefix}_3d`:
         return <Tout3d {...block} />;
      case `${prefix}_3e`:
         return <Tout3e {...block} />;
      case `${prefix}_3f`:
         return <Tout3f {...block} />;
      case `${prefix}_4a`:
         return <Tout4a {...block} />;
      case `${prefix}_4b`:
         return <Tout4b {...block} />;
      case `${prefix}_5a`:
         return <Tout5a {...block} />;
      default:
         return null;
   }
};

export default Tout;
