import IntroButton from 'components/IntroButton';
import Footnotes from 'components/Footnotes';
import RichText from 'components/RichText';
import classNames from 'classnames';

const Statistics1b = ({
    settings,
    introduction,
    statistics,
    button,
    footnotes,
    meta,
}) => {
    return (
        <section
            
            className={classNames('section', {
                ...settings,
            })}
            {...meta}
        >
            <div className="container">
                <IntroButton introduction={introduction} button={button} />
                <div className={'grid-md-2'}>
                    {statistics.map((statistic, index) => {
                        const { id, codename, title, description, stat } =
                            statistic;
                        return (
                            <article
                                key={index}
                                className="statistic"
                                data-kontent-item-id={id}
                                data-kontent-element-codename={codename}
                                data-kontent-add-button={!!codename}
                                data-kontent-add-button-render-position="right"
                                data-kontent-add-button-insert-position="after"
                            >
                                <div className="statistic__head">
                                    {stat && (
                                        <div className="statistic__stat">
                                            {stat}
                                        </div>
                                    )}
                                    {title && (
                                        <RichText
                                            className="statistic__title"
                                            data={title}
                                        />
                                    )}
                                </div>
                                {description && (
                                    <RichText
                                        className="statistic__description"
                                        data={description}
                                    />
                                )}
                            </article>
                        );
                    })}
                </div>

                {footnotes?.value && (
                    <Footnotes className="mt-14" footnotes={footnotes} />
                )}
            </div>
        </section>
    );
};

export default Statistics1b;
