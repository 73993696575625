/**
 * Resources Block
 * A resources block is a collection of documents(PDF, etc), videos, or links to support a given topic.
 */

import Resources1a from './variants/Resources1a';
import Resources1b from './variants/Resources1b';
import Resources1c from './variants/Resources1c';

import Resources2a from './variants/Resources2a';
import Resources2b from './variants/Resources2b';
import Resources2c from './variants/Resources2c';
import Resources2d from './variants/Resources2d';

import Resources3a from './variants/Resources3a';
import Resources3b from './variants/Resources3b';

import Resources4a from './variants/Resources4a';
import Resources4b from './variants/Resources4b';

const prefix = 'resources';

const Resources = ({ ...block }) => {

   switch (block.variant) {
      case `${prefix}_1a`:
         return <Resources1a {...block} />;
      case `${prefix}_1b`:
         return <Resources1b {...block} />;
      case `${prefix}_1c`:
         return <Resources1c {...block} />;
      case `${prefix}_2a`:
         return <Resources2a {...block} />;
      case `${prefix}_2b`:
         return <Resources2b {...block} />;
      case `${prefix}_2c`:
         return <Resources2c {...block} />;
      case `${prefix}_2d`:
         return <Resources2d {...block} />;
      case `${prefix}_3a`:
         return <Resources3a {...block} />;
      case `${prefix}_3b`:
         return <Resources3b {...block} />;
      case `${prefix}_4a`:
         return <Resources4a {...block} />;
      case `${prefix}_4b`:
         return <Resources4b {...block} />;
      default:
         return null;
   }
};

export default Resources;
