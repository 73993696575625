/**
 * Table Block
 * Description goes here...
 */

import Table1a from './variants/Table1a';

const prefix = 'table';

const Table = ({ ...block }) => {
   switch (block.variant) {
      case `${prefix}_1a`:
         return <Table1a {...block} />;
      default:
         return null;
   }
};

export default Table;
