import Icon from 'components/Icon';
import RichText from 'components/RichText';

const Location = ({ icon, phone_label, id, codename, office_name, address, communication_items}) => {
   return (
      <div
         className="location"
         data-kontent-item-id={id}
         data-kontent-element-codename={codename}
      >
         {icon && (
            <Icon
               className="location__icon"
               icon={'fa-thin fa-map-location-dot'}
            />
         )}
         <h5 className="location__office">{office_name}</h5>
         {address && <RichText className="location__address" data={address} />}
         {communication_items?.map((item, index) => {
            const { phone_number } = item
            return (
               <div key={index} className="location__phone">
                  <strong>{phone_label}:</strong> {phone_number}
               </div>
            );
         })}
      </div>
   );
};

export default Location;
