import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import classNames from 'classnames';
import ResourceButtons from '../elements/ResourceButtons';

const Resources2b = ({ settings, introduction, footnotes, resources, meta }) => {
   return (
      <section
         
         className={classNames('section', {
            ...settings
         })}
         {...meta}
      >
         <div className="container">
            <Introduction {...introduction} />
            <div className="grid-md-3">
               {resources.map((resource, index) => {
                  return <ResourceButtons key={index} index={index} resource={resource} />;
               })}
            </div>
            {footnotes?.value && <Footnotes className="mt-14" footnotes={footnotes} />}
         </div>
      </section>
   );
};

export default Resources2b;
