import Introduction from 'components/Introduction';
import InfoBody from '../elements/InfoBody';
import Media from 'components/Media';
import classNames from 'classnames';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';
import breakpoints from 'assets/data/breakpoints';

const Info1f = ({
    settings,
    horizontal_spacing,
    vertical_alignment,
    introduction,
    description,
    buttons,
    media,
    footnotes,
    meta,
}) => {
    const { width } = useWindowDimensions();
    return (
        <section
            className={classNames('section info', {
                ...settings,
            })}
            {...meta}
        >
            <div className="container">
                <div
                    className={classNames('grid-lg-12', {
                        'info--vertical': vertical_alignment,
                    })}
                >
                    {media && (
                        <div className="col-lg-4 d-none d-lg-block">
                            <Media media={media} />
                        </div>
                    )}
                    <div
                        className={classNames({
                            'col-start-lg-6 col-end-lg-13':
                                media && horizontal_spacing === 'default',
                            'col-start-lg-5 col-end-lg-13':
                                media && horizontal_spacing === 'tight',
                            'col-lg-9': !media,
                        })}
                    >
                        <Introduction {...introduction} size={'sm'} />
                        {width < breakpoints.tablet && media && (
                            <div className={'info__media d-block d-lg-none'}>
                                <Media media={media} />
                            </div>
                        )}
                        <InfoBody
                            description={description}
                            buttons={buttons}
                            footnotes={footnotes}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Info1f;
