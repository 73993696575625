import Introduction from 'components/Introduction';
import InfoBody from '../elements/InfoBody';
import Media from 'components/Media';
import classNames from 'classnames';

const Info3b = ({
    settings,
    introduction,
    description,
    buttons,
    media,
    footnotes,
    meta,
}) => {
    return (
        <section
            className={classNames('section info', {
                ...settings,
            })}
            {...meta}
        >
            <div className="container">
                <div className="bg-white p-4 p-md-8">
                    <Introduction {...introduction} size={'sm'} />
                    {media && (
                        <div className={'info__media'}>
                            <Media media={media} />
                        </div>
                    )}
                    <InfoBody
                        description={description}
                        buttons={buttons}
                        buttonBlock
                        footnotes={footnotes}
                    />
                </div>
            </div>
        </section>
    );
};

export default Info3b;
