import Card from 'components/Card';
import Footnotes from 'components/Footnotes';
import classNames from 'classnames';
import IntroButton from 'components/IntroButton';

const Products4b = ({ settings, introduction, button, products, footnotes, meta }) => {
	return (
		<section
			className={classNames('section', {
				...settings
			})}
			{...meta}
		>
			<div className='container'>
				<IntroButton introduction={introduction} button={button} />
				<div className='grid-md-1'>
					{products.map((product, index) => {
						return (
							<Card
								key={index}
								variant={4}
								{...product}
							/>
						);
					})}
				</div>
				{footnotes?.value && <Footnotes className='mt-14' footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Products4b;
