/**
 * Locations Block
 * A locations block is used to display a collection of regions and offices.
 */

import Locations1a from './variants/Locations1a';
import Locations1b from './variants/Locations1b';

import Locations2a from './variants/Locations2a';
import Locations2b from './variants/Locations2b';

const prefix = 'locations';

const Locations = ({ ...block }) => {
	switch (block.variant) {
		case `${prefix}_1a`:
			return <Locations1a {...block} />;
		case `${prefix}_1b`:
			return <Locations1b {...block} />;
		case `${prefix}_2a`:
			return <Locations2a {...block} />;
		case `${prefix}_2b`:
			return <Locations2b {...block} />;
		default:
			return null;
	}
};

export default Locations;
