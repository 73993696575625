import Script from 'next/script';

const FormMarketoIframe = ({ form }) => {
    const formId = form.split('_')[1];
    return (
        <div className="container section">
            <style>
                {`form.mktoForm {
font-family: 'Merriweather Sans', Arial, sans-serif !important;
font-size: inherit;
line-height: inherit;
margin: 0 auto;
margin-bottom: 50px;
}

@media screen and (min-width: 1024px) {
form.mktoForm#mktoForm_1280 {
width: 721px !important;
}
}

form.mktoForm .mktoFormRow {
padding-top: 25px;
}

form.mktoForm div.mktoHtmlText p {
color: #505759;
font-family: 'Merriweather Sans', Arial, sans-serif !important;
font-size: 16px;
}

/* Label */
form.mktoForm label {
color: #505759;
font-size: 16px;
letter-spacing: 0;
margin-bottom: 15px;
text-transform: none;
font-weight: normal;
white-space: normal;
margin-top: 0 !important;
line-height: 1 !important;
}

form.mktoForm label.mktoLabel {
white-space: nowrap;
font-weight: 700;
}

form#mktoForm_1280 .mktoLabel {
display: block;
float: none;
padding-left: 10px;
}

form.mktoForm .mktoAsterix {
color: #505759;
float: left;
padding-left: 0;
padding-right: 5px;
}

.mktoForm * {
font-family: 'Merriweather Sans', Arial, sans-serif !important;
}

/* Input control */
form.mktoForm input[type="text"], form.mktoForm input[type="url"],
form.mktoForm input[type="email"], form.mktoForm input[type="tel"],
form.mktoForm input[type="number"], form.mktoForm input[type="date"],
form.mktoForm textarea.mktoField {
background-color: #FFFFFF;
background-image: none;
border: 1px solid #cccccc;
border-color: #b9b9b9;
border-radius: 0;
color: #555555;
font-family: 'Merriweather Sans', Arial, sans-serif !important;
font-size: 16px !important;
height: 37px;
line-height: 1 !important;
padding: 6px 12px !important;
}

form.mktoForm textarea.mktoField {
height: 70px;
}

form.mktoForm input[type="text"]:focus, form.mktoForm input[type="url"]:focus,
form.mktoForm input[type="email"]:focus, form.mktoForm input[type="tel"]:focus,
form.mktoForm input[type="number"]:focus, form.mktoForm input[type="date"]:focus {
border-color: #000;
border-width: 1px;
box-shadow: 0 0 0 1px #000 inset;
}

.mktoForm .mktoRadioList > label, .mktoForm .mktoCheckboxList > label {
font-family: 'Merriweather Sans', Arial, sans-serif !important;
}

form.mktoForm input[type=radio] {
margin-top: 3px;
}

/* Select control */
form.mktoForm select {
background-color: #FFFFFF;
border: none;
border-radius: 0;
color: #555555;
font-family: 'Merriweather Sans', Arial, sans-serif !important;
font-size: 14px !important;
height: 37px !important;
line-height: 37px !important;
outline: 1px solid #ccc;
padding: 0 12px 0 12px !important;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
background-image: url('/Themes/Newbrand/Styles/images/edwards/select-arrows.png'); 
background-repeat: no-repeat;
background-position: 287px 3px;
}

form.mktoForm select.open {
background-position: 287px -37px;
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
form.mktoForm select {
background-position: 270px 3px;
}

form.mktoForm select.open {
	background-position: 270px -37px;
}
}
/* iPhone6 landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {

form.mktoForm select {
background-position: 240px 3px;
}

form.mktoForm select.open {
	background-position: 240px -37px;
}
}

form.mktoForm select:focus, form.mktoForm select:active {
border-color: #000;
border-width: 1px;
box-shadow: 0 0 0 2px #000 inset;
}

form.mktoForm select option {
color: #555555;
font-size: 14px;
line-height: 37px;
padding: 0 2px 1px;
}

/* Text Area control */
form.mktoForm textarea {
border: 1px solid #cccccc;
border-color: #b9b9b9;
background: #edeeee;
color: #505759;
font-size: 14px !important;
height: 230px;
overflow-x: hidden !important;
overflow-y: auto !important;
padding: 15px 0 15px 20px !important;
resize: none !important;
}

/* Button control */
form.mktoForm .mktoButtonWrap.mktoSimple button.mktoButton {
background: #c8102e !important;
background-image: none;
border: 1px solid #b00e29 !important;
border-radius: 0;
border-width: 1px;
color: #fff !important;
cursor: pointer;
display: inline-block;
font-family: 'Merriweather Sans', Arial, sans-serif !important;
font-size: 23px;
font-weight: normal;
letter-spacing: 1px;
line-height: 26px;
margin-bottom: 0;
padding: 7px 35px;
text-align: center;
text-transform: none !important;
vertical-align: middle;
white-space: nowrap;
}

form.mktoForm .mktoButtonWrap.mktoSimple button.mktoButton:hover,
form.mktoForm .mktoButtonWrap.mktoSimple button.mktoButton:focus {
border-width: 1px !important;
}

form.mktoForm .mktoButtonWrap.mktoSimple button.mktoButton:hover {
border-color: #780a1c !important;
background-color: #990c23 !important;
}

form.mktoForm .mktoButtonWrap.mktoSimple button.mktoButton:focus {
border-color: #780a1c !important;
background-color: #990c23 !important;
}

form.mktoForm .mktoButtonWrap.mktoSimple button.mktoButton:active {
background: transparent;
border-color: #c8102e;
box-shadow: none;
color: black;
outline: none;
}


/*Marketo form related css*/
@media only screen and (max-width: 480px) {
form.mktoForm textarea, .mktoForm .mktoFormRow .mktoField, form.mktoForm select.mktoField.mktoHasWidth, form.mktoForm select.mktoField.mktoHasWidth.mktoRequired, select#Country.mktoField.mktoHasWidth.mktoRequired, select#State.mktoField.mktoHasWidth.mktoRequired, select#Specialty__c.mktoField.mktoHasWidth.mktoRequired, select#Topics_Of_Interest__c.mktoField.mktoHasWidth, textarea#Notes__c.mktoField.mktoHasWidth {
font-size: 11px !important;
}

.mktoForm a {
padding-left: 0px !important;
padding-right: 0px !important;
}

.mktoForm .mktoRadioList, .mktoForm .mktoCheckboxList {
width: auto;
padding: 0px;
}
}

@media only screen and (max-width: 340px) {
form.mktoForm textarea, .mktoForm .mktoFormRow .mktoField, form.mktoForm select.mktoField.mktoHasWidth, form.mktoForm select.mktoField.mktoHasWidth.mktoRequired, select#Country.mktoField.mktoHasWidth.mktoRequired, select#State.mktoField.mktoHasWidth.mktoRequired, select#Specialty__c.mktoField.mktoHasWidth.mktoRequired, select#Topics_Of_Interest__c.mktoField.mktoHasWidth, textarea#Notes__c.mktoField.mktoHasWidth {
font-size: 9px !important;
}
}

@media only screen and (min-width: 480px) {
form.mktoForm select.mktoField {
font-size: 16px !important;
}
}

form.mktoForm select.mktoField.open {
background-position: right -37px !important;
}

form.mktoForm select.mktoField {
padding: 0 12px !important;
font-family: 'Merriweather Sans', Arial, sans-serif !important;
vertical-align: middle !important;
background-repeat: no-repeat;
background-position: right 3px !important;
}

`}
            </style>
            <Script
                src="//info.edwards.com/js/forms2/js/forms2.min.js"
                strategy="beforeInteractive"
            />
            <form id={`mktoForm_${formId}`}></form>
            <Script
                id="marketo-form"
                dangerouslySetInnerHTML={{
                    __html: `MktoForms2.loadForm("//info.edwards.com", "769-NOZ-917", ${formId})`,
                }}
            />
        </div>
    );
};

export default FormMarketoIframe;
