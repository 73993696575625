import dynamic from 'next/dynamic';

import Info from 'blocks/Info';
import Features from 'blocks/Features';
import Products from 'blocks/Products';
import Resources from 'blocks/Resources';
import Tout from 'blocks/Tout';
import Tabs from 'blocks/Tabs';
import Accordion from 'blocks/Accordion';
import Statistics from 'blocks/Statistics';
import People from 'blocks/People';
import Locations from 'blocks/Locations';
import Contact from 'blocks/Contact';
import Form from 'blocks/Form';
import Table from 'blocks/Table';

const JobSearch = dynamic(() => import('blocks/JobSearch'));
const Gallery = dynamic(() => import('blocks/Gallery'));
const ThreeDViewer = dynamic(() => import('blocks/ThreeDViewer'));
const Lookup = dynamic(() => import('blocks/Lookup'));
const Social = dynamic(() => import('blocks/Social'));
const Toggles = dynamic(() => import('blocks/Toggles'));
const Criteria = dynamic(() => import('blocks/Criteria'));
const Articles = dynamic(() => import('blocks/Articles'));
const Compliance = dynamic(() => import('blocks/Compliance'));
const FindASurgeon = dynamic(() => import('blocks/FindASurgeon'));
const Posts = dynamic(() => import('blocks/Posts'));

const ContentBlock = ({ block }) => {
    if (!block) {
        return null;
    }
    switch (block.type) {
        case 'info':
            return <Info {...block} />;
        case 'features':
            return <Features {...block} />;
        case 'products':
            return <Products {...block} />;
        case 'resources':
            return <Resources {...block} />;
        case 'tout':
            return <Tout {...block} />;
        case 'tabs':
            return <Tabs {...block} />;
        case 'accordion':
            return <Accordion {...block} />;
        case 'statistics':
            return <Statistics {...block} />;
        case 'people':
            return <People {...block} />;
        case 'gallery':
            return <Gallery {...block} />;
        case 'locations':
            return <Locations {...block} />;
        case 'contact':
            return <Contact {...block} />;
        case 'form':
            return <Form {...block} />;
        case 'articles':
            return <Articles {...block} />;
        case 'table':
            return <Table {...block} />;
        case 'lookup':
            return <Lookup {...block} />;
        case 'three_d':
            return <ThreeDViewer {...block} />;
        case 'compliance':
            return <Compliance {...block} />;
        case 'criteria':
            return <Criteria {...block} />;
        case 'toggles':
            return <Toggles {...block} />;
        case 'social':
            return <Social {...block} />;
        case 'fas':
            return <FindASurgeon {...block} />;
        case 'posts':
            return <Posts {...block} />;
        case 'job_search':
            return <JobSearch {...block} />;
        default:
            return null;
    }
};

export default ContentBlock;
