import Modal from 'components/Modal';
import useModal from 'lib/hooks/useModal';
import Icon from 'components/Icon';
import RichText from 'components/RichText';
import Image from 'components/Media/elements/Image';
import VideoModalContent from 'components/Media/elements/VideoModalContent';
import Media from 'components/Media';

const VideoResource = ({ id, codename, media, title, description }) => {
    const { isOpen, setIsOpen } = useModal();
    return (
        <>
            {/* Modal Videos with thumbnail */}
            {media?.thumbnail && (
                <>
                    <button
                        onClick={() => setIsOpen(true)}
                        className="resource"
                        data-kontent-item-id={id}
                        data-kontent-element-codename={codename}
                        data-kontent-add-button={!!codename}
                        data-kontent-add-button-render-position="right"
                        data-kontent-add-button-insert-position="after"
                    >
                        {media?.thumbnail && (
                            <div className="resource__head modal__video">
                                <Icon
                                    icon={'fa-duotone fa-circle-play'}
                                    className="modal__icon"
                                />
                                <Image
                                    wrapperClassName={'resource__image'}
                                    className={'br-lg'}
                                    layout="fill"
                                    objectFit="cover"
                                    objectPosition={'center'}
                                    image={media.thumbnail}
                                />
                            </div>
                        )}
                        <div className="resource__body">
                            {title?.value && (
                                <RichText
                                    className={'resource__title h4'}
                                    data={title}
                                />
                            )}
                            {description?.value && (
                                <RichText
                                    className={'resource__description'}
                                    data={description}
                                />
                            )}
                            {media?.button?.cta_text && (
                                <div className={'resource__link'}>
                                    {media.button.cta_text}
                                    <Icon
                                        icon={'fa-light fa-arrow-right'}
                                        className="resource__arrow"
                                    />
                                </div>
                            )}
                        </div>
                    </button>
                    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                        <VideoModalContent
                            video={media}
                            setIsOpen={setIsOpen}
                            disableModal
                        />
                    </Modal>
                </>
            )}
            {/* Inline Videos */}
            {!media?.thumbnail && (
                <div
                    className="resource"
                    data-kontent-item-id={id}
                    data-kontent-element-codename={codename}
                    data-kontent-add-button={!!codename}
                    data-kontent-add-button-render-position="right"
                    data-kontent-add-button-insert-position="after"
                >
                    <div className="resource__head">
                        <div className="resource__video">
                            <Media media={media} />
                        </div>
                    </div>
                    <div className="resource__body">
                        {title?.value && (
                            <RichText
                                className={'resource__title h4'}
                                data={title}
                            />
                        )}
                        {description?.value && (
                            <RichText
                                className={'resource__description'}
                                data={description}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default VideoResource;
