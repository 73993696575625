import { useEffect, useState } from 'react';
import breakpoints from 'assets/data/breakpoints';

const useJumpLinks = ({ blocks, width }) => {
	const [active, setActive] = useState(false);
	const [activeLink, setActiveLink] = useState(null);
	// Functionality that keeps track of page scroll position, when jumplinks are active, active jumplinks link.
	useEffect(() => {
		const sections = document.querySelectorAll('.jumppoint');
		const amountScrolled = Math.round(window.scrollY);
		const jumplinksTop =
			amountScrolled + document.querySelector('.jumplinks__wrapper').getBoundingClientRect().top;
		const linkHeight = width > breakpoints.tablet ? 104 : 84;
		const lastSection =
			sections[sections.length - 1].offsetTop +
			sections[sections.length - 1].clientHeight -
			linkHeight;
		const handleScroll = () => {
			const amountScrolled = Math.round(scrollY);
			const scrollPosition = amountScrolled + linkHeight; // accounts for the current scroll position plus the height of the jumplinks;
			// Check to see if the window has scrolled passed the top of the jump links
			if (amountScrolled >= jumplinksTop) {
				setActive(true);
			}

			// Removes active state of jumplinks if window is not scrolling within jumplinks area
			if (amountScrolled < jumplinksTop) {
				setActive(false);
			}

			// Removes active state of individual jumplink if window is not scrolling within jumplinks area
			if (
				amountScrolled < sections[0].offsetTop ||
				(amountScrolled < sections[0].offsetTop && amountScrolled > lastSection) ||
				amountScrolled > lastSection
			) {
				setActiveLink(null);
			}

			// Loop through each section and determine what jumplink is active
			sections.forEach(section => {
				// 1) Scroll position is inside a specific section
				// 2 ) Scroll position is more than that of the top of jumplinks navigaton
				if (
					scrollPosition > section.offsetTop &&
					scrollPosition < section.offsetTop + section.clientHeight &&
					amountScrolled >= jumplinksTop
				) {
					setActiveLink(section.getAttribute('id'));
				}
			});
		};
		// Initially fire function on page load
		handleScroll();

		// When browser is resized recalculate height of jumplinks
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [blocks, width]);
	return { active, activeLink };
};

export default useJumpLinks;
