import ContentBlock from './ContentBlock';
import JumpLinks from './JumpLinks';

const JumpLinkContentZone = ({ blocks, width, jump_links }) => {
   return (
      <>
         <JumpLinks
            key={Date.now()}
            blocks={blocks}
            jump_links={jump_links}
            width={width}
         />

         {/* Blocks that arent part of jump links */}
         <div className="content content--jumplinks">
            {blocks.map((block, index) => {
               if (!block?.jump_link) {
                  return (
                     <ContentBlock key={index} block={block} />
                  );
               }
            })}

            {/* Blocks that are part of jump links */}
            {jump_links?.map((jump_link, index) => {
               return (
                  <section id={jump_link.id} className="jumppoint" key={index}>
                     {blocks.map((block, index) => {
                        if (block?.jump_link === jump_link.id) {
                           return (
                              <ContentBlock
                                 key={index}
                                 block={block}
                              />
                           );
                        }
                     })}
                  </section>
               );
            })}
         </div>
      </>
   );
};

export default JumpLinkContentZone;
