/**
 * Tabs Block
 * A Tabs Block is used to group related topics
 */

import Tabs1a from './variants/Tabs1a';
import Tabs1b from './variants/Tabs1b';
import Tabs1c from './variants/Tabs1c';

const prefix = 'tabs';

const Tabs = ({ ...block }) => {
	switch (block.variant) {
		case `${prefix}_1a`:
			return <Tabs1a {...block} />;
		case `${prefix}_1b`:
			return <Tabs1b {...block} />;
		case `${prefix}_1c`:
			return <Tabs1c {...block} />;
		default:
			return null;
	}
};

export default Tabs;
