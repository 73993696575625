import classNames from 'classnames';
import JumpLink from './JumpLink';
import useJumpLinks from '../hooks/useJumpLinks';
import { useEffect, useRef } from 'react';

const JumpLinks = ({ blocks, jump_links, width }) => {
	const { active, activeLink } = useJumpLinks({ blocks, width });
	const container = useRef();
	useEffect(() => {
		if (activeLink) {
			const link = document.querySelector(`a[href="#${activeLink}"]`);
			container.current.scroll({
				left: link.offsetLeft,
				behavior: 'smooth',
			});
		}
	}, [activeLink]);
	return (
		<nav className='jumplinks'>
			<div
				className={classNames('jumplinks__wrapper', {
					'jumplinks__wrapper--active': active,
				})}
			>
				<div className='jumplinks__container' ref={container}>
					{jump_links?.map((jump_link, index) => {
						return <JumpLink key={index} {...jump_link} activeLink={activeLink} width={width} />;
					})}
				</div>
			</div>
		</nav>
	);
};

export default JumpLinks;
