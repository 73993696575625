import classNames from 'classnames';
import Media from 'components/Media';
import RichText from 'components/RichText';
import Icon from 'components/Icon';
import useModal from 'lib/hooks/useModal';
import Modal from 'components/Modal';
import PeopleModal from './PeopleModal';

const Person = ({
    id,
    codename,
    job_title,
    full_name,
    description,
    cta_text,
    media,
    variant,
}) => {
    const { isOpen, setIsOpen } = useModal();
    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                data-kontent-item-id={id}
                data-kontent-element-codename={codename}
                data-kontent-add-button={!!codename}
                data-kontent-add-button-render-position="right"
                data-kontent-add-button-insert-position="after"
                className={classNames('person', {
                    [`person--${variant}`]: variant,
                })}
            >
                {media && (
                    <Media
                        layout={'fill'}
                        objectFit={'cover'}
                        objectPosition={'center'}
                        wrapperClassName={'person__image'}
                        className="circle"
                        media={media}
                    />
                )}
                <div className="person__bio">
                    {full_name && (
                        <h4 className="person__fullname">{full_name}</h4>
                    )}
                    {job_title && (
                        <RichText
                            className="person__occupation"
                            data={job_title}
                        />
                    )}
                    {description && (
                        <RichText
                            className="person__description person__description--shorten"
                            data={description}
                        />
                    )}
                    {cta_text && (
                        <div className={'person__link'}>
                            {cta_text}
                            <Icon
                                icon={'fa-light fa-arrow-right'}
                                className="person__arrow"
                            />
                        </div>
                    )}
                </div>
            </button>
            <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                <PeopleModal
                    media={media}
                    job_title={job_title}
                    full_name={full_name}
                    description={description}
                />
            </Modal>
        </>
    );
};

export default Person;
