/**
 * People Block
 * Use People block when you need to spotlight a group of people, such as showing bios, displaying conference speakers, or providing testimonials.
 */

import People1a from './variants/People1a';
import People1b from './variants/People1b';
import People1c from './variants/People1c';

import People2a from './variants/People2a';
import People2b from './variants/People2b';
import People2c from './variants/People2c';

import People3a from './variants/People3a';
import People3b from './variants/People3b';
import People3c from './variants/People3c';

import People4a from './variants/People4a';
import People4b from './variants/People4b';
import People4c from './variants/People4c';

const prefix = 'people';

const People = ({ ...block }) => {
	switch (block.variant) {
		case `${prefix}_1a`:
			return <People1a {...block} />;
		case `${prefix}_1b`:
			return <People1b {...block} />;
		case `${prefix}_1c`:
			return <People1c {...block} />;
		case `${prefix}_2a`:
			return <People2a {...block} />;
		case `${prefix}_2b`:
			return <People2b {...block} />;
		case `${prefix}_2c`:
			return <People2c {...block} />;
		case `${prefix}_3a`:
			return <People3a {...block} />;
		case `${prefix}_3b`:
			return <People3b {...block} />;
		case `${prefix}_3c`:
			return <People3c {...block} />;
		case `${prefix}_4a`:
			return <People4a {...block} />;
		case `${prefix}_4b`:
			return <People4b {...block} />;
		case `${prefix}_4c`:
			return <People4c {...block} />;
		default:
			return null;
	}
};

export default People;
