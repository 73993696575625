import FormSelector from '../elements/FormSelector';
import Introduction from 'components/Introduction';
import useForm from 'lib/hooks/useForm';
import Footnotes from 'components/Footnotes';
import { useRef } from 'react';
import RichText from 'components/RichText';
import classNames from 'classnames';
import Results from '../elements/Results';
import MarketoScript from '../elements/MarketoScript';
import Form from '../elements/Form';

const Form2b = ({
    settings,
    introduction,
    form,
    form_element,
    language,
    fields,
    footnotes,
    thank_you_message,
    meta,
}) => {
    const ref = useRef();
    const { success, error, isPending, results, handleSubmit } = useForm({
        form,
        ref,
        form_element,
    });
    return (
        <>
            <MarketoScript />
            <section
                className={classNames('section form__wrapper', {
                    ...settings,
                })}
                {...meta}
                ref={ref}
                data-form={form}
            >
                <div className="container">
                    {!success && (
                        <div className="grid-lg-12">
                            <div className="col-lg-8">
                                <Introduction {...introduction} />
                                {form_element ? (
                                    <Form
                                        form={form_element}
                                        isPending={isPending}
                                        submit={handleSubmit}
                                    />
                                ) : (
                                    <FormSelector
                                        form={form}
                                        handleSubmit={handleSubmit}
                                        isPending={isPending}
                                        fields={fields}
                                        language={language}
                                    />
                                )}
                                {footnotes?.value && (
                                    <Footnotes
                                        className="mt-10"
                                        footnotes={footnotes}
                                    />
                                )}
                                {error && (
                                    <RichText
                                        className="form__error"
                                        data={{ value: error }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {success && (
                        <Results
                            success={success}
                            form_element={form_element}
                            thank_you_message={thank_you_message}
                            results={results}
                        />
                    )}
                </div>
            </section>
        </>
    );
};

export default Form2b;
