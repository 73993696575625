import { useRouter } from 'next/router';

/**
 * Used for vault pdf submissions. If ?expand=true is added to the URL of a page it will expand all Accordions, Tabs, Gallery, and hide the floating ISI
 * @returns expand
 */

const useExpand = () => {
    const router = useRouter();
    const expand = router.query.expand;
    return { expand };
};
export default useExpand;
