/**
 * Statistics Block
 * Use an statistics block when important statistics need to be highlighted.
 */

import Statistics1a from './variants/Statistics1a';
import Statistics1b from './variants/Statistics1b';
import Statistics1c from './variants/Statistics1c';

import Statistics2a from './variants/Statistics2a';
import Statistics2b from './variants/Statistics2b';
import Statistics2c from './variants/Statistics2c';

import Statistics3a from './variants/Statistics3a';
import Statistics3b from './variants/Statistics3b';

import Statistics4a from './variants/Statistics4a';
import Statistics4b from './variants/Statistics4b';

const prefix = 'statistics';

const Statistics = ({ ...block }) => {
	switch (block.variant) {
		case `${prefix}_1a`:
			return <Statistics1a {...block} />;
		case `${prefix}_1b`:
			return <Statistics1b {...block} />;
		case `${prefix}_1c`:
			return <Statistics1c {...block} />;
		case `${prefix}_2a`:
			return <Statistics2a {...block} />;
		case `${prefix}_2b`:
			return <Statistics2b {...block} />;
		case `${prefix}_2c`:
			return <Statistics2c {...block} />;
		case `${prefix}_3a`:
			return <Statistics3a {...block} />;
		case `${prefix}_3b`:
			return <Statistics3b {...block} />;
		case `${prefix}_4a`:
			return <Statistics4a {...block} />;
		case `${prefix}_4b`:
			return <Statistics4b {...block} />;
		default:
			return null;
	}
};

export default Statistics;
