import RichText from 'components/RichText';
import Button from 'components/Button';
import Footnotes from 'components/Footnotes';
import classNames from 'classnames';

const InfoBody = ({ description, buttons, footnotes, size, buttonBlock }) => {
    return (
        <>
            {description?.value && (
                <RichText
                    className={classNames('info__description', {
                        'info__description--lg': size === 'lg',
                    })}
                    data={description}
                />
            )}
            {buttons && (
                <div className="info__buttons">
                    {buttons.map((button, key) => {
                        return (
                            <Button
                                key={key}
                                className={'info__button'}
                                {...button}
                                block={buttonBlock}
                            >
                                {button.cta_text}
                            </Button>
                        );
                    })}
                </div>
            )}
            {footnotes?.value && <Footnotes footnotes={footnotes} />}
        </>
    );
};

export default InfoBody;
