import classNames from 'classnames';
import RichText from 'components/RichText';

const Footnotes = ({ footnotes, className }) => {
	return (
		<RichText
			className={classNames('footnotes', {
				[`${className}`]: className,
			})}
			data={footnotes}
		/>
	);
};

export default Footnotes;
