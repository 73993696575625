import classNames from 'classnames';
import RichText from 'components/RichText';
import breakpoints from 'assets/data/breakpoints';

const JumpLink = ({ title, id, activeLink, width }) => {
	const handleClick = e => {
		e.preventDefault();
		const linkHeight = width > breakpoints.tablet ? 104 / 2 : 84 - 10;
		const jumplinks = document.querySelectorAll('.jumplinks__link');
		let id = null;
		for (const jumplink of jumplinks) {
			if (jumplink.contains(e.target)) {
				id = jumplink.getAttribute('href').replace('#', '');
			}
		}
		const section = document.getElementById(id).offsetTop;
		window.scrollTo({ top: section - linkHeight, behavior: 'smooth' });
	};
	return (
		<a
			className={classNames('jumplinks__link', {
				'jumplinks__link--active': id === activeLink,
			})}
			onClick={handleClick}
			href={`#${id}`}
		>
			<RichText data={title} />
		</a>
	);
};

export default JumpLink;
