import ContentBlock from './ContentBlock';

const NormalContentZone = ({ blocks }) => {
	return (
		<div className='content'>
			{blocks?.map((block, index) => {
				return <ContentBlock key={index} block={block} />;
			})}
		</div>
	);
};

export default NormalContentZone;
