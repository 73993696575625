import { useState } from 'react';
import Tab from './elements/Tab';
import TabContent from './elements/TabContent';
import classNames from 'classnames';
import Dropdown from 'components/Dropdown';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';
import breakpoints from 'assets/data/breakpoints';
import useExpand from 'lib/hooks/useExpand';

const Tabs = ({ tabs, variant, className, iconStyle, grow, icons }) => {
    const [activeTab, setActiveTab] = useState(1);
    const { width } = useWindowDimensions();
    const objectData = tabs?.map((tab, index) => {
        const count = index + 1;
        const { title, media } = tab;
        return { title, media, id: count };
    });
    const onSelectChange = (item) => {
        setActiveTab(item);
    };
    const { expand } = useExpand();
    return (
        <>
            <div
                className={classNames('tabs', {
                    [`${className}`]: className,
                })}
            >
                {width < breakpoints.tablet && (
                    <Dropdown
                        className="dropdown--tabs"
                        objectData={objectData}
                        onSelectChange={onSelectChange}
                        icon={icons}
                    />
                )}
                {width >= breakpoints.tablet && (
                    <div className={classNames('tabs__menu')}>
                        {tabs?.map((tab, index) => {
                            const count = index + 1;
                            return (
                                <Tab
                                    key={count}
                                    tab={tab}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    variant={variant}
                                    iconStyle={iconStyle}
                                    grow={grow}
                                    tabID={count}
                                />
                            );
                        })}
                    </div>
                )}

                <div className="tabs__content">
                    {tabs?.map((tab, index) => {
                        const count = index + 1;
                        return (
                            (activeTab === count || expand) && (
                                <TabContent key={index} tab={tab} />
                            )
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default Tabs;
