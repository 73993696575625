import FormSelector from '../elements/FormSelector';
import Introduction from 'components/Introduction';
import Phone from 'blocks/Contact/elements/Phone';
import Email from 'blocks/Contact/elements/Email';
import useForm from 'lib/hooks/useForm';
import Media from 'components/Media';
import Footnotes from 'components/Footnotes';
import RichText from 'components/RichText';
import classNames from 'classnames';
import { useRef } from 'react';
import Results from '../elements/Results';
import MarketoScript from '../elements/MarketoScript';
import Form from '../elements/Form';

const Form1b = ({
    settings,
    introduction,
    description,
    communications,
    media,
    form,
    form_element,
    language,
    fields,
    footnotes,
    thank_you_message,
    meta,
}) => {
    const ref = useRef();
    const { success, error, isPending, results, handleSubmit } = useForm({
        form,
        ref,
        form_element,
    });
    return (
        <>
            <MarketoScript />
            <section
                className={classNames('section form__wrapper', {
                    ...settings,
                })}
                {...meta}
                ref={ref}
                data-form={form}
            >
                <div className="container">
                    <div className="grid-lg-12 formmain">
                        <div className="col-lg-4 formside">
                            <div className="formside__inner">
                                <RichText
                                    className="formside__description"
                                    data={description}
                                />
                                <div className="formside__contact contact">
                                    {communications?.map((item, index) => {
                                        const { type } = item;
                                        return (
                                            <div
                                                className="formside__contact-item"
                                                key={index}
                                            >
                                                {type === 'phone' && (
                                                    <Phone data={item} />
                                                )}
                                                {type === 'email' && (
                                                    <Email data={item} />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {media && (
                                <Media
                                    wrapperClassName={'formside__image'}
                                    layout={'fill'}
                                    objectFit="cover"
                                    objectPosition={'bottom'}
                                    media={media}
                                    disableElevation
                                    disableCaption
                                />
                            )}
                        </div>
                        <div className="col-lg-8 pl-lg-4 pt-lg-12 pr-lg-12 pb-lg-12">
                            <div>
                                {!success && (
                                    <>
                                        <Introduction
                                            {...introduction}
                                            size={'sm'}
                                        />
                                        {form_element ? (
                                            <Form
                                                form={form_element}
                                                isPending={isPending}
                                                submit={handleSubmit}
                                            />
                                        ) : (
                                            <FormSelector
                                                form={form}
                                                handleSubmit={handleSubmit}
                                                isPending={isPending}
                                                fields={fields}
                                                language={language}
                                            />
                                        )}
                                        {footnotes?.value && (
                                            <Footnotes
                                                className="mt-10"
                                                footnotes={footnotes}
                                            />
                                        )}
                                        {error && (
                                            <RichText
                                                className="form__error"
                                                data={{ value: error }}
                                            />
                                        )}
                                    </>
                                )}
                                {success && (
                                    <Results
                                        success={success}
                                        form_element={form_element}
                                        thank_you_message={thank_you_message}
                                        results={results}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Form1b;
