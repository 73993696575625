import classNames from 'classnames';
import Parse from 'react-html-parser';
import { useEffect } from 'react';
import { getVisibilityConditions } from 'lib/utils/getFormElementFields';

const Checkbox = ({
    register,
    label,
    name,
    visibility_conditions,
    validation,
    type,
    parse_rich_text,
    data,
    split,
    errors,
    options,
    setValue,
    reset,
}) => {
    if (!options) return null;
    validation = {
        ...validation,
        pattern: {
            value: validation?.pattern
                ? new RegExp(validation.pattern.value)
                : null,
            message: validation?.pattern ? validation?.pattern?.message : null,
        },
    };
    useEffect(() => {
        if (data[reset]) {
            setValue(name, '');
        }
    }, [data[reset]]);
    return (
        getVisibilityConditions({ data, visibility_conditions }) && (
            <div
                className="form__field form__field--full"
                role="group"
                aria-label="checkboxes"
            >
                {label && (
                    <div className="form__label form__label--multi">
                        {label}
                    </div>
                )}
                <div
                    className={classNames('form__validation', {
                        'form__validation--error': errors[name],
                        'grid-md-2 row-gap-0': split,
                    })}
                >
                    {options.map((option, key) => {
                        return (
                            <label key={key} className="form__item">
                                <input
                                    className="form__selector"
                                    type={type}
                                    {...register(name, validation)}
                                    value={option.value}
                                />
                                <div className="form__check"></div>
                                {parse_rich_text
                                    ? Parse(option.label)
                                    : option.label}
                            </label>
                        );
                    })}
                    {errors[name] && (
                        <div className="form__helper">
                            {errors[name]?.message}
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default Checkbox;
