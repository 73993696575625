import IntroButton from 'components/IntroButton';
import Card from 'components/Card';
import Footnotes from 'components/Footnotes';
import classNames from 'classnames';

const Products2b = ({ settings, introduction, button, products, footnotes, meta }) => {
	return (
		<section
			className={classNames('section', {
				...settings
			})}
			{...meta}
		>
			<div className='container'>
				<IntroButton introduction={introduction} button={button} />
				<div className='grid-lg-3 grid-md-2 row-gap-md-8'>
					{products.map((product, index) => {
						return (
							<Card
								key={index}
								variant={1}
								{...product}
							/>
						);
					})}
				</div>
				{footnotes?.value && <Footnotes className='mt-14' footnotes={footnotes} />}
			</div>
		</section>
	);
};

export default Products2b;
