import Introduction from 'components/Introduction';
import Footnotes from 'components/Footnotes';
import Person from '../elements/Person';
import classNames from 'classnames';

const People4b = ({ settings, introduction, footnotes, people, meta }) => {
   return (
      <section
         
         className={classNames('section', {
            ...settings
         })}
         {...meta}
      >
         <div className="container">
            <Introduction {...introduction} />
            <div className="grid-1 grid-lg-3 grid-md-2 row-gap-4 row-gap-md-12">
               {people.map((person, index) => {
                  return <Person key={index} variant={4} {...person} />;
               })}
            </div>

            {footnotes?.value && <Footnotes className="mt-14" footnotes={footnotes} />}
         </div>
      </section>
   );
};

export default People4b;
