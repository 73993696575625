import dynamic from 'next/dynamic';

/* === Critical Care Forms === */

const Form18630 = dynamic(() =>
    import('blocks/Form/forms/critical-care/Form18630')
); // General Contact
const Form18689 = dynamic(import('blocks/Form/forms/critical-care/Form18689')); // Vascular
const Form18690 = dynamic(import('blocks/Form/forms/critical-care/Form18690')); // Download
const Form18984 = dynamic(import('blocks/Form/forms/critical-care/Form18984')); // Clinical Education Support

/* === Surgical Forms === */

const Form18733 = dynamic(import('blocks/Form/forms/surgical/Form18733')); // General Contact
const Form20270 = dynamic(import('blocks/Form/forms/surgical/Form20270')); // Informed Discussion Guide

/* === TMTT Forms === */

const Form19505 = dynamic(import('blocks/Form/forms/tmtt/Form19505')); // General Contact

/* === THV Forms === */

const Form18715 = dynamic(import('blocks/Form/forms/thv/Form18715')); // Structural Heart
const Form18884 = dynamic(import('blocks/Form/forms/thv/Form18884')); // TAVR Experience Feedback

/* === Corporate Forms === */

const Form18716 = dynamic(import('blocks/Form/forms/corporate/Form18716')); // Healthcare Professionals
const Form18718 = dynamic(import('blocks/Form/forms/corporate/Form18718')); // General Contact Inquiries
const Form18809 = dynamic(import('blocks/Form/forms/corporate/Form18809')); // Diversity
const Form18732 = dynamic(import('blocks/Form/forms/corporate/Form18732')); // Patient Experience
const Form18848 = dynamic(import('blocks/Form/forms/corporate/Form18848')); // Patient Voice
const Form18953 = dynamic(import('blocks/Form/forms/corporate/Form18953')); // Patient Support Center
const Form18929 = dynamic(import('blocks/Form/forms/corporate/Form18929')); // Request Volunteers
const FormEdwardsLearningApp = dynamic(
    import('blocks/Form/forms/corporate/FormEdwardsLearningApp')
); // Edwards Learning App

/* === Legal Forms === */

const FormPrivacyPolicy = dynamic(import('../forms/legal/FormPrivacyPolicy')); // Privacy Policy
const FormCCPA = dynamic(import('../forms/legal/FormCCPA')); // CCPA
const FormDSAR = dynamic(import('../forms/legal/FormDSAR')); // DSAR

/* === Sandbox Forms === */

const Form18034 = dynamic(import('blocks/Form/forms/Sandbox/Form18034'));

/* === AU Forms === */

const FormAuLogin = dynamic(import('../forms/AU/AUFormLogin'));

/* === Global Supply Chain Forms === */

const Form20940 = dynamic(import('../forms/global-supply-chain/Form20940')); // Supplier Application

/* === Japan Forms === */

const Form21133 = dynamic(import('../forms/JP/Form21133')); //JAPAN - AR Opt In
const Form21284 = dynamic(import('../forms/JP/Form21284')); //JAPAN - Recruitment Inquiry

const FormSelector = ({ form, ...props }) => {
    switch (form) {
        case 'form_18630':
            return <Form18630 {...props} />; // CC - General Contact
        case 'form_18689':
            return <Form18689 {...props} />; // CC - Vascular
        case 'form_18690':
            return <Form18690 {...props} />; // CC - Download
        case 'form_18984':
            return <Form18984 {...props} />; // CC - Clinical Education Support
        case 'form_18733':
            return <Form18733 {...props} />; // SURG - General Contact
        case 'form_20270':
            return <Form20270 {...props} />; // SURG - Informed Discussion Guide
        case 'form_19505':
            return <Form19505 {...props} />; // TMTT - General Contact
        case 'form_18715':
            return <Form18715 {...props} />; // THV - Structural Heart
        case 'form_18884':
            return <Form18884 {...props} />; // THV - TAVR Experience Feedback
        case 'form_18716':
            return <Form18716 {...props} />; // CORP - Healthcare Professionals
        case 'form_18718':
            return <Form18718 {...props} />; // CORP - General Contact Inquiries
        case 'form_18809':
            return <Form18809 {...props} />; // CORP - Diversity
        case 'form_18732':
            return <Form18732 {...props} />; // CORP - Patient Experience
        case 'form_18953':
            return <Form18953 {...props} />; // CORP - Patient Support Center
        case 'form_18848':
            return <Form18848 {...props} />; // CORP - Patient Voice
        case 'form_18929':
            return <Form18929 {...props} />; // CORP - Request Volunteers
        case 'form_edwards_learning_app':
            return <FormEdwardsLearningApp {...props} />; // CORP - Edwards Learning App
        case 'form_privacy_policy':
            return <FormPrivacyPolicy {...props} />; // Legal - Privacy Policy
        case 'form_ccpa':
            return <FormCCPA {...props} />; // Legal - CCPA
        case 'form_dsar':
            return <FormDSAR {...props} />; // Legal - CCPA
        case 'form_18034':
            return <Form18034 {...props} />; // Sandbox - Form 1
        case 'form_aulogin':
            return <FormAuLogin {...props} />; // Au-Login Form
        case 'form_20940':
            return <Form20940 {...props} />; // GSC - Supplier Application - 20940
        case 'form_21133':
            return <Form21133 {...props} />; // JAPAN - AR Opt In - 21133
        case 'form_21284':
            return <Form21284 {...props} />; // JAPAN - Recruitment Inquiry - 21284
        default:
            return null;
    }
};

export default FormSelector;
