/**
 * Info Block
 * Use this to highlight one topic - such as a product feature or a therapy - in a short, concise way.
 */

import Info1a from './variants/Info1a';
import Info1b from './variants/Info1b';
import Info1c from './variants/Info1c';
import Info1d from './variants/Info1d';
import Info1e from './variants/Info1e';
import Info1f from './variants/Info1f';

import Info2a from './variants/Info2a';
import Info2b from './variants/Info2b';
import Info2c from './variants/Info2c';
import Info2d from './variants/Info2d';
import Info2e from './variants/Info2e';
import Info2f from './variants/Info2f';

import Info3a from './variants/Info3a';
import Info3b from './variants/Info3b';

import Info4a from './variants/Info4a';

const prefix = 'info';

const Info = ({ ...block }) => {
   switch (block.variant) {
      case `${prefix}_1a`:
         return <Info1a {...block} />;
      case `${prefix}_1b`:
         return <Info1b {...block} />;
      case `${prefix}_1c`:
         return <Info1c {...block} />;
      case `${prefix}_1d`:
         return <Info1d {...block} />;
      case `${prefix}_1e`:
         return <Info1e {...block} />;
      case `${prefix}_1f`:
         return <Info1f {...block} />;
      case `${prefix}_2a`:
         return <Info2a {...block} />;
      case `${prefix}_2b`:
         return <Info2b {...block} />;
      case `${prefix}_2c`:
         return <Info2c {...block} />;
      case `${prefix}_2d`:
         return <Info2d {...block} />;
      case `${prefix}_2e`:
         return <Info2e {...block} />;
      case `${prefix}_2f`:
         return <Info2f {...block} />;
      case `${prefix}_3a`:
         return <Info3a {...block} />;
      case `${prefix}_3b`:
         return <Info3b {...block} />;
      case `${prefix}_4a`:
         return <Info4a {...block} />;
      default:
         return null;
   }
};

export default Info;
