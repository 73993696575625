import Introduction from 'components/Introduction';
import Button from 'components/Button';
import classNames from 'classnames';

const IntroButton = ({ introduction, button }) => {
	return (
		<>
			<Introduction
				className={classNames({ [`mb-6`]: button?.cta_text })}
				{...introduction}
			/>
			{button?.cta_text && (
				<Button className={'mb-14'} {...button}>
					{button.cta_text}
				</Button>
			)}
		</>
	);
};

export default IntroButton;
