/**
 * Products Block
 * A products block is used to show off a collection of products.
 */

import Products1a from './variants/Products1a';

import Products2a from './variants/Products2a';
import Products2b from './variants/Products2b';
import Products2c from './variants/Products2c';

import Products3a from './variants/Products3a';
import Products3b from './variants/Products3b';
import Products3c from './variants/Products3c';

import Products4a from './variants/Products4a';
import Products4b from './variants/Products4b';

const prefix = 'products';

const Products = ({ ...block }) => {

   switch (block.variant) {
      case `${prefix}_1a`:
         return <Products1a {...block} />;
      case `${prefix}_2a`:
         return <Products2a {...block} />;
      case `${prefix}_2b`:
         return <Products2b {...block} />;
      case `${prefix}_2c`:
         return <Products2c {...block} />;
      case `${prefix}_3a`:
         return <Products3a {...block} />;
      case `${prefix}_3b`:
         return <Products3b {...block} />;
      case `${prefix}_3c`:
         return <Products3c {...block} />;
      case `${prefix}_4a`:
         return <Products4a {...block} />;
      case `${prefix}_4b`:
         return <Products4b {...block} />;

      default:
         return null;
   }
};

export default Products;
