import Icon from 'components/Icon';
import Media from 'components/Media';
import RichText from 'components/RichText';
import Link from 'components/Link';

const LinkResource = ({ id, codename, media, title, description }) => {
	return (
		<Link href={media?.button?.cta_link} className='resource' target={media?.button?.target}>
				<article
					data-kontent-item-id={id}
					data-kontent-element-codename={codename}
					data-kontent-add-button={!!codename}
					data-kontent-add-button-render-position='right'
					data-kontent-add-button-insert-position='after'
				>
					{media?.media && (
						<div className='resource__head'>
							<Media
								wrapperClassName={'resource__image'}
								className={'br-lg'}
								layout='fill'
								objectFit='cover'
								objectPosition={'center'}
								media={media?.media}
								disableCaption
								disableElevation
							/>
						</div>
					)}
					<div className='resource__body'>
						{title?.value && <RichText className={'resource__title h4'} data={title} />}
						{description?.value && <RichText className={'resource__description'} data={description} />}
						{media?.button?.cta_text && (
							<div className={'resource__link'}>
								{media?.button?.cta_text}
								<Icon icon={'fa-light fa-arrow-right'} className='resource__arrow' />
							</div>
						)}
					</div>
				</article>
		
		</Link>
	);
};

export default LinkResource;
